import { Suspense, useEffect } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import routes from './routes';
import { usePopup } from './utils/PopupContext';
import { setPopupHandler } from './utils/PopupHandler';
import LoaderFallback from './components/Loader/LoaderFallback';
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

function App() {
  const { handlePopupToggle } = usePopup();

    useEffect(() => {
        setPopupHandler(handlePopupToggle);
        return () => setPopupHandler(null);
    }, [handlePopupToggle]);

  const renderRoutes = ((routes) => {
    return routes.map(({ path, element, children, protectedRoute, label }) => (
      <Route
        key={path}
        path={path}
        element={element}>
        {children && renderRoutes(children)}
      </Route>
    ))
  })

  ReactGA.initialize([
    {
      trackingId: "G-0B9X0RVW3H",
    },
  ]);
  ReactPixel.init("1207392447043568");
  ReactPixel.pageView();

  return (
      <Suspense fallback={<LoaderFallback/>}>
        <Routes>{renderRoutes(routes)}</Routes>
      </Suspense>
  );
}

export default App;
