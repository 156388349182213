import { lazy } from "react";

const About = lazy(() => import("./containers/StaticPages/AboutUs/AbouUs"));
const Terms = lazy(() => import("./containers/StaticPages/Terms/Terms"));
const DrawResults = lazy(() => import("./containers/StaticPages/DrawResults/DrawResults"));
const HowToPlay = lazy(() => import("./containers/StaticPages/HowToPlay/HowToPlay"));
const PayStackDashboard = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/PayStackDashboard"));

const Homepage = lazy(() => import("./containers/Homepage/Homepage"));
const Game = lazy(() => import("./containers/Game/Game"));
const WinEstimation = lazy(() => import("./containers/StaticPages/WinEstimation/WinEstimation"));
const FAQ = lazy(() => import("./containers/StaticPages/Faq/Faq"));
const PrivacyPolicy = lazy(() => import("./containers/StaticPages/PrivacyPolicy/PrivacyPolicy"));
const ContactUs = lazy(() => import("./containers/StaticPages/ContactUs/ContactUs"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const Profile = lazy(() => import("./containers/Dashboard/Profile"));
const WalletAndTransaction = lazy(() => import("./containers/Dashboard/WalletAndTransaction"));
const Deposit = lazy(() => import("./containers/Dashboard/Deposit"));
const Withdraw = lazy(() => import("./containers/Dashboard/Withdrawal"));
const Tickets = lazy(() => import("./containers/Dashboard/Tickets"));
const InterswitchDeposit = lazy(() => import("./containers/Dashboard/DepositGateways/Interswitch"));
const BankAccountPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/BankAccountDeposit"));
const PaymentPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/PaymentPopup"));
const RedirectionPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/RedirectionPopup"));
const UssdPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/USSDDeposit"));
const TemporaryBankAccount = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/TemporaryBankAccount"));
const CashoutInterswitch = lazy(() => import("./containers/Dashboard/WithdrawGateways/VerifyWithdraw"));
const CashoutPaystack = lazy(() => import("./containers/Dashboard/WithdrawGateways/Paystack"));
const routes = [

    {
        path: "/",
        element: <Homepage />,
        children: [],
    },
    {
        path: "/game",
        element: <Game />,
        children: [],
    },
    {
        path: "/winestimation",
        element: <WinEstimation />,
        children: [],
    },
    {
        path: "/faq",
        element: <FAQ />,
        children: [],
    },
    {
        path: "/privacyPolicy",
        element: <PrivacyPolicy />,
        children: [],
    },
    {
        path: "/contactUs",
        element: <ContactUs />,
        children: [],
    },
    {
        path: "/aboutUs",
        element: <About />,
        children: [],
    },
    {
        path: "/terms",
        element: <Terms />,
        children: [],
    },
    {
        path: "/DrawResults",
        element: <DrawResults />,
        children: [],
    },
    {
        path: "/HowToPlay",
        element: <HowToPlay />,
        children: [],
    },
    {
        path: "/Dashboard",
        element: <Dashboard />,
        children: [
            {
                path: "Profile",
                element: <Profile />,
            },
            {
                path: "WalletAndTransaction",
                element: <WalletAndTransaction />,
            },
            {
                path: "Deposit",
                element: <Deposit />,
                children: [{
                    path: "Interswitch",
                    element: <InterswitchDeposit />
                }, {
                    path: "Paystack",
                    element: <PayStackDashboard />,
                    children: [
                        {
                            path: "BankDeposit",
                            element: <BankAccountPaystack />
                        },
                        {
                            path: "temporaryBankAccount",
                            element: <TemporaryBankAccount />
                        },
                        {
                            path: "PaymentPopup",
                            element: <PaymentPaystack />
                        },
                        {
                            path: "RedirectionPopup",
                            element: <RedirectionPaystack />
                        },
                        {
                            path: "UssdDeposit",
                            element: <UssdPaystack />
                        }
                    ]
                }]
            },
            {
                path: "Withdrawal",
                element: <Withdraw />,
                children: [{
                    path: "Interswitch",
                    element: <CashoutInterswitch/>,
                },{
                     path: "Paystack",
                     element: <CashoutPaystack/>
                }]
            },
            {
                path: "Tickets",
                element: <Tickets />,
            },
        ],
    },

];

export default routes;