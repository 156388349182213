import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import gameSlice from "./gameSlice";
import walletSlice from "./walletSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        game:gameSlice,
        wallet: walletSlice,
    },
});