import { createSlice } from "@reduxjs/toolkit";
import { buyTicket, sellTicket, ticketSummary } from '../utils/Api';
import ApiClients from "../utils/ApiClients";
import { getWallet } from "./authSlice";

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    draws: [],
    gameLoader: null,
    gameMessage: null,
    gameStatus: null,
  },
  reducers: {
    saveDraws: (state, action) => {
      state.draws = action.payload;
    },
    clearDraws: (state) => {
      state.draws = [];
    },
    initiateGame: (state, action) => {
      state.gameLoader = action.payload.loader;
      state.gameMessage = action.payload.gameMessage;
      state.gameStatus = action.payload.gameStatus;
    }
  },
});

export const { saveDraws, clearDraws, initiateGame } = gameSlice.actions;

export const saveBet = (gameTypeVariation, stakeAmount, numbers, drawIdNmb, minNmb, maxNmb, unchangedStake) => {
  const privateId = `bet_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
  return (dispatch, getState) => {
    const newDraw = {
      privateId,
      gameTypeVariation,
      stakeAmount,
      numbers,
      drawIdNmb,
      minNmb,
      maxNmb,
      unchangedStake
    };
    const currentDraws = getState().game.draws || [];
    const updatedDraws = [...currentDraws, newDraw];
    dispatch(saveDraws(updatedDraws));
    return true;
  };
};

export const TicketSummaryFct = (amount, drawName, numbers, variation) => {
  return (dispatch) => {
    let url = ticketSummary(amount, drawName, numbers, variation);
    ApiClients.get(url).then((res) => {
      console.log(res);
    }).catch((res) => {
      console.log(res);
    })
  }
}

export const deleteBet = (privateId) => {
  return (dispatch, getState) => {
    const currentDraws = getState().game.draws || [];
    const updatedDraws = currentDraws.filter(draw => draw.privateId !== privateId);
    dispatch(saveDraws(updatedDraws));
    return true;
  };
};

export const buyTicketFct = (drawId, tickets, handlePopupToggle, closePopup) => {
  if (tickets && tickets.length > 1) {
    let url = buyTicket(localStorage.getItem('clientId'));
    return (dispatch) => {
      dispatch(initiateGame({ loader: true, gameMessage: null, gameStatus: null }));
      const dto = {
        accessChannel: "WEB",
        drawId: drawId,
        msisdn: localStorage.getItem('msisdn'),
        operator: localStorage.getItem('operator')?.toUpperCase(),
        source: "wallet",
        tickets: tickets.map(item => ({
          numbers: item.numbers.filter(num => num).join(','),
          stake: parseInt(item.unchangedStake),
          variation: item.gameTypeVariation
        }))
      }
      ApiClients.post(url, dto).then(response => {
        dispatch(initiateGame({ loader: false, gameMessage: null, gameStatus: null }));
        handlePopupToggle("game");
        if (response.data.status == 0) {
          dispatch(initiateGame({ loader: false, gameMessage: 'ticket purchased successfuly', gameStatus: 'sucess' }));
          dispatch(getWallet());
          dispatch(clearDraws());
        } else {
          dispatch(initiateGame({ loader: false, gameMessage: response.data.message, gameStatus: 'error' }));
        }
        setTimeout(() => {
          closePopup();
        }, 2000)
      }).catch((res) => {
        console.error(res);
      })
    }
  } else {
    let url = sellTicket(localStorage.getItem('clientId'));
    return (dispatch) => {
      dispatch(initiateGame({ loader: true, gameMessage: null, gameStatus: null }));
      console.log('tickets', tickets);
      const dto = {
        accessChannel: "WEB",
        drawId: drawId,
        msisdn: localStorage.getItem('msisdn'),
        operator: localStorage.getItem('operator')?.toUpperCase(),
        source: "wallet",
        numbers: tickets[0]?.numbers?.filter(num => num).join(','),
        stake: parseInt(tickets[0].unchangedStake),
        variation: tickets[0].gameTypeVariation,
      }
      ApiClients.post(url, dto).then(response => {
        dispatch(initiateGame({ loader: false, gameMessage: null, gameStatus: null }));
        handlePopupToggle("game");
        if (response.data.status == 0) {
          dispatch(initiateGame({ loader: false, gameMessage: 'ticket purchased successfuly', gameStatus: 'sucess' }));
          dispatch(getWallet());
          dispatch(clearDraws());
        } else {
          dispatch(initiateGame({ loader: false, gameMessage: response.data.message, gameStatus: 'error' }));
        }
        setTimeout(() => {
          closePopup();
        }, 2000)
      }).catch((res) => {
        console.error(res);
      })
    }
  }
}

export default gameSlice.reducer;