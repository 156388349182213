// src/contexts/PopupContext.js
import React, { createContext, useState, useContext } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [activePopup, setActivePopup] = useState(null);

    const handlePopupToggle = (popup) => {
        setActivePopup(() => popup);
        document.body.classList.add('mobileMenuOpen');
    };

    const closePopup = () => {
        setActivePopup(null);
        document.body.classList.remove('mobileMenuOpen');
    };

    return (
        <PopupContext.Provider value={{ activePopup, handlePopupToggle, closePopup }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopup = () => useContext(PopupContext);
